import { AsyncPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';

import { Language } from '@offconon/shared/features/generated-api';
import { SystemService } from '@offconon/shared/features/generated-api';
import { SharedUiVirtualscrollDropdownComponent } from '@offconon/shared/ui/shared-ui-virtualscroll-dropdown';
import { CommonSelectOption } from '@offconon/shared/ui/shared-ui-virtualscroll-multiselect';
import { CommonDropdownAndMultiselectService, DropdownData } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-langauge-dropdown-virtual-scroll-common',
  standalone: true,
  imports: [ButtonModule, SharedUiVirtualscrollDropdownComponent, TranslateModule, AsyncPipe],
  providers: [CommonDropdownAndMultiselectService],
  templateUrl: './langauge-dropdown-virtual-scroll-common.component.html',
})
export class LangaugeDropdownVirtualScrollCommonComponent implements OnInit, OnChanges {
  @Input({ required: true }) languageFormControl: any;
  @Input() parameters: any;
  @Input() filter_languages: any;
  @Input() filter_invert_languages: any;
  @Input() selectFirst = true;
  @Input() selectedId: number;
  @Output() selectedLangaugeEvent = new EventEmitter<any>();

  public commonDropdownAndMultiselectService = inject(CommonDropdownAndMultiselectService);
  public systemService = inject(SystemService);
  public translateService = inject(TranslateService);

  languageList$: Observable<CommonSelectOption[]>;

  dropdownData$ = new BehaviorSubject<
    DropdownData<{
      id?: number;
      name?: string;
      lang_en_name?: string;
      nativename?: string;
      sign?: boolean;
      translation_supported?: boolean;
      languages?: any;
      invert_languages?: any;
    }>
  >({ page: 1, pageSize: 50, dataList: [] });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parameters'] || changes['languages'] || changes['invert_languages']) {
      this.dropdownData$.next({
        page: 1,
        pageSize: 50,
        searchJson: {
          translation_supported: this.parameters?.translation_supported
            ? this.parameters?.translation_supported
            : true,
          sign: this.parameters?.sign ? this.parameters?.sign : undefined,
          languages: this.filter_languages ? this.filter_languages : undefined,
          invert_languages: this.filter_invert_languages ? this.filter_invert_languages : undefined,
        },
        selectedId: undefined,
        dataList: [],
      });
    }
  }

  ngOnInit(): void {
    this.initializeComponent();

    this.commonDropdownAndMultiselectService.selectedOptions$.subscribe({
      next: (option: any) => {
        if (option) {
          this.languageFormControl.patchValue(option.id);
          this.selectedLangaugeEvent.emit(option);
        }
      },
    });
  }

  private initializeComponent() {
    this.languageList$ = this.commonDropdownAndMultiselectService.fetchData<
      SystemService,
      {
        id?: number;
        lang_en_name?: string;
        name?: string;
        nativename?: any;
        iso_code?: any;
        sign?: boolean;
        translation_supported?: boolean;
        languages?: any;
        invert_languages?: any;
      }
    >({
      service: this.systemService,
      action: 'systemLanguagesList',

      dropdownData: this.dropdownData$,
      mapData: (data: Language[]) => {
        return data?.map((item) => {
          return {
            name: this.translateService.instant('' + item.lang_en_name) || '-',
            id: item.id,
            nativename: item.lang_native_name,
            mixed:
              this.translateService.instant('' + item.lang_en_name) + ' ' + item.lang_native_name,
            iso_code: item.iso_code,
          };
        });
      },
      selectFirst: this.selectFirst,
    });

    this.dropdownData$.next({
      page: 1,
      pageSize: 50,
      selectedId: this.selectedId,
      searchJson: {
        translation_supported: this.parameters?.translation_supported
          ? this.parameters?.translation_supported
          : true,
        sign: this.parameters?.sign ? this.parameters?.sign : undefined,
        languages: this.filter_languages ? this.filter_languages : undefined,
        invert_languages: this.filter_invert_languages ? this.filter_invert_languages : undefined,
      },
      dataList: [],
    });
  }

  updateLanguageFormControl(states: CommonSelectOption[] = []) {
    const languageFormControl = this.languageFormControl;

    if (states?.length === 0) {
      languageFormControl.clearValidators();
      languageFormControl.disable();
    } else {
      languageFormControl.setValidators([Validators.required]);
      languageFormControl.enable();
    }
    languageFormControl.updateValueAndValidity();
  }

  select(event: any) {
    this.languageFormControl.patchValue(event.id);
    this.selectedLangaugeEvent.emit(event);
  }

  paginationAndSearchOutput(event: any) {
    lastValueFrom(this.languageList$).then((data) => {
      this.updateLanguageFormControl(data);
    });
    this.commonDropdownAndMultiselectService.onDataChange(event, this.dropdownData$, {
      lang_en_name: event.searchString,
      translation_supported: this.parameters?.translation_supported
        ? this.parameters?.translation_supported
        : true,
      sign: this.parameters?.sign ? this.parameters?.sign : undefined,
      languages: this.filter_languages ? this.filter_languages : undefined,
      invert_languages: this.filter_invert_languages ? this.filter_invert_languages : undefined,
    });
  }
}
